/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../firebase/userContext";
import logo from '../../assets/logos/logo1.png'

const Login = () => {
    const navigate = useNavigate();
    const { setEmail, setPassword, handleLogin, error } = useContext(UserContext)

    return (
        <div id="register-layout">
            <img src={logo} id="logo" />
            <div id="login-container">
                <p id="login"> <span style={{color: 'brown'}}>login</span> </p>
                <input
                id="email"
                placeholder="email" 
                onChange={(e) => setEmail(e.target.value)}
                />
                <input
                id="password"
                placeholder="password"
                type="password"
                onChange={(e) => setPassword(e.target.value)} 
                />
                 {error && <p id="error-message">{error}</p>}
                <p id="terms">forgot password</p>
                <button id="register-button" onClick={handleLogin}>login</button>
            </div>
            <h1 id="login-link" onClick={() => navigate('/')}>sign up</h1>
        </div>
    )
}

export default Login;