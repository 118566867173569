/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import { db } from "../../firebase/config";
import { collectionGroup, getDocs } from "firebase/firestore";
import NavBar from "../../components/navBar";
import { useNavigate } from "react-router-dom";
import './filmHome.css'
import logo from '../../assets/logos/logo1.png'

const FilmHome = () => {
    const [films, setFilms] = useState([]);
    const [selectedFilm, setSelectedFilm] = useState(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAllFilms = async () => {
            try {
                const filmsRef = collectionGroup(db, 'films');
                const querySnapshot = await getDocs(filmsRef);

                const filmList = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                    userId: doc.ref.parent.parent.id,
                    username: doc.data().username || 'Unknown' // Ensure username is set
                }));
                console.log("Fetched Films:", filmList); // Debugging
                setFilms(filmList);
            } catch (err) {
                console.error("Error fetching films:", err);
                setError("Failed to fetch films. Please try again.");
            }
        };

        fetchAllFilms();
    }, []);

    const handleFilmClick = (film) => {
        
        setSelectedFilm(film);
        setIsVideoPlaying(false); 
    };

    const handlePosterClick = () => {
        setIsVideoPlaying(true); 
    };

    const handleCloseModal = () => {
        setSelectedFilm(null);
        setIsVideoPlaying(false);
    };

    const handleNavigateToUserProfile = () => {
        if (selectedFilm && selectedFilm.username) {
            console.log("Navigating to:", `/creators/${selectedFilm.username}`); // Debugging
            navigate(`/creators/${selectedFilm.username}`);
        } else {
            console.error("No username available for navigation");
        }
    };

    return (
        <div id="filmhome-layout">
            <img src={logo} id="logo-profile" />
            <NavBar />
            <div id="filmhome-container">
                <p>work in progress</p>
                {error && <p id="error-message">{error}</p>}
                {films.map((film, index) => (
                    <div key={index} className="filmcard-home" onClick={() => handleFilmClick(film)}>
                        <img
                            src={film.posterUrl}
                            className="filmposter-home"
                            alt={film.name}
                        />
                        <p className="filmname-home">{film.name}</p>
                    </div>
                ))}
            </div>

            {selectedFilm && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div className="modal-content-film" onClick={(e) => e.stopPropagation()}>
                    <span className="close-modal" onClick={handleCloseModal}>&times;</span>
                        {isVideoPlaying ? (
                            <video
                                className="modal-video-player"
                                controls
                                autoPlay
                                src={selectedFilm.filmUrl}
                                onClick={(e) => e.stopPropagation()} 
                            />
                        ) : (
                            <div id="film-modal-poster">
                                <img
                                    src={selectedFilm.posterUrl}
                                    className="modal-poster-film"
                                    alt={selectedFilm.name}
                                    onClick={handlePosterClick}
                                />
                                <p id="video-playback-symbol" onClick={handlePosterClick}>▶</p>
                            </div>
                        )}
                        <h1 className="modal-filmtitle">{selectedFilm.name}</h1>
                        <p className="modal-film-description">{selectedFilm.description}</p>
                        <p className="modal-film-genre">Genre: {selectedFilm.genre}</p>
                        <p
                            className="artistusername-modal"
                            onClick={handleNavigateToUserProfile} // Changed from anonymous function to named function
                        >
                            @{selectedFilm.username}
                        </p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilmHome;