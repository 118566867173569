/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect } from "react";
import NavBar from "../../components/navBar";
import { db } from "../../firebase/config";
import { collectionGroup, getDocs } from "firebase/firestore";
import MusicPlayer from "../../components/musicPlayer";
import { useNavigate } from "react-router-dom";
import './musicHome.css'
import logo from '../../assets/logos/logo1.png'

const MusicHome = () => {
    const [tracks, setTracks] = useState([]);
    const [selectedTrack, setSelectedTrack] = useState(null);
    const [playingTrackId, setPlayingTrackId] = useState(null); // For play/pause control
    const navigate = useNavigate();

    useEffect(() => {
        const fetchTracks = async () => {
            try {
                const tracksRef = collectionGroup(db, 'tracks');
                const snapshot = await getDocs(tracksRef);

                const fetchedTracks = snapshot.docs.map(doc => ({
                    id: doc.id,
                    coverURL: doc.data().coverURL || '',
                    name: doc.data().name,
                    title: doc.data().title,
                    username: doc.data().username,
                    fileURL: doc.data().fileURL || ''
                }));

                setTracks(fetchedTracks);
            } catch (err) {
                console.error("Error fetching tracks:", err);
            }
        };

        fetchTracks();
    }, []);

    const handleCoverClick = (track) => {
        setSelectedTrack(track);
        setPlayingTrackId(null); // Ensure the play button is shown initially
    };

    const handleCloseModal = () => {
        setSelectedTrack(null);
        setPlayingTrackId(null); // Stop playback when closing modal
    };

    // const handlePlayPause = () => {
    //     if (playingTrackId === selectedTrack?.id) {
    //         setPlayingTrackId(null); // Pause if already playing
    //     } else {
    //         setPlayingTrackId(selectedTrack?.id); // Play the selected track
    //     }
    // };

    return (
        <div id="musichome-layout">
            <img src={logo} id="logo-profile" />
            <NavBar />
            <div id="musichome-container">
                {tracks.map(track => (
                    <div 
                        key={track.id} 
                        className="musiccard-home" 
                        onClick={() => handleCoverClick(track)}
                    >
                        <p className="music-artist-home"> <span style={{color: 'brown'}} > {track.name} </span> </p>
                        <img
                            src={track.coverURL}
                            alt={track.name}
                            className="songcover-home"
                            onError={(e) => e.target.src = 'fallback-image-url'}
                        />
                        <p className="music-name">{track.title}</p>
                    </div>
                ))}
            </div>

            {selectedTrack && (
                <div className="modal-overlay">
                    <div id="music-content-modal">
                        <span className="close-modal" onClick={handleCloseModal}>&times;</span>
                        <p className="modal-songtitle">{selectedTrack.title}</p>
                        <h1 style={{cursor: 'pointer'}} className="modal-artist" onClick={() => navigate(`/creators/${selectedTrack.username}`)} >{selectedTrack.name}</h1>
                        <p className="artistusername-modal" onClick={() => navigate(`/creators/${selectedTrack.username}`)}>@{selectedTrack.username}</p>
                        <img style={{cursor: 'pointer'}} src={selectedTrack.coverURL} alt={selectedTrack.title} className="musiccover-modal" onClick={() => navigate(`/creators/${selectedTrack.username}`)}/>
                       
                        <MusicPlayer 
                            className='modal-musicplayer' 
                            fileURL={selectedTrack.fileURL} 
                            isPlaying={playingTrackId === selectedTrack.id}
                            onPlay={() => setPlayingTrackId(selectedTrack.id)}
                            onPause={() => { if (playingTrackId === selectedTrack.id) setPlayingTrackId(null); }}
                        />
                    </div>
                </div>
            )}
        </div>
    );
}

export default MusicHome;