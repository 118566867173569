/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from 'react';
import { UserContext } from '../../firebase/userContext';
import logo from '../../assets/logos/logo1.png'
import './username.css'

const Username = () => {
    const [username, setUsername] = useState('');
    const [name, setName] = useState('');
    const { handleExplorerUsername, error, cleanError } = useContext(UserContext);

    const handleCreateUsername = async () => {
        cleanError();
        const errorMsg = await handleExplorerUsername(username, name);
        if (!errorMsg) {
            // Handle successful username creation (navigation is already managed in UserContext)
        }
    };

    return (
        <div id="register-layout">
            <img src={logo} id='logo-profile' />
            <div id="username-container">
                <p id="add-details">  <span style={{color: 'brown'}}>add details</span> </p>
                <input
                    placeholder='name'
                    id='explorer-name-register'
                    value={name}
                    onChange={(e) => setName(e.target.value)} 
                />
                <input
                    placeholder="username"
                    id="explorer-username-register"
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                />
                <button id="explorer-create-button" onClick={handleCreateUsername}>
                    create
                </button>
                {error && <p className="error-message">{error}</p>}
            </div>
        </div>
    );
}

export default Username;
