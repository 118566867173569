/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from "react";
import './navBar.css'
import profileIcon from '../assets/icons/profileIcon.png';
import notificationIcon from '../assets/icons/notificationIcon.png';
import { useNavigate } from "react-router-dom";
import { UserContext } from "../firebase/userContext";

const NavBar = () => {
    const navigate = useNavigate();
    const { role, currentUser } = useContext(UserContext);
    const profilePath = role === 'creator' ? `/creator/${currentUser.username}` : `/explorer/${currentUser.username}`;

    return (
        <div id="navbar-container">
            <img src={profileIcon} id="profile-icon" onClick={() => navigate(profilePath)}/>
            <h1 id="explore-nav" onClick={() => navigate(`/home/${currentUser.username}`)}>explore</h1>
            <img src={notificationIcon} id="notification-icon" onClick={() => navigate(`/notification/${currentUser.username}`)}/>
        </div>
    )
}

export default NavBar;