/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { db } from "../../../firebase/config";
import { collection, query, getDocs, doc, deleteDoc } from "firebase/firestore";
// import deleteIcon from '../../../assets/icons/deleteIcon.png'
import './filmGallery.css'

const FilmGallery = () => {
    const { currentUser, role } = useContext(UserContext);
    const [films, setFilms] = useState([]);
    const [error, setError] = useState("");
    const [selectedFilm, setSelectedFilm] = useState(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        const fetchFilms = async () => {
            try {
                const filmsRef = collection(db, `${role}/${currentUser.uid}/films`);
                const q = query(filmsRef);
                const querySnapshot = await getDocs(q);
                const filmList = querySnapshot.docs.map(doc => doc.data());
                setFilms(filmList);
            } catch (err) {
                console.error("Error fetching films:", err);
                setError("Failed to fetch films. Please try again.");
            }
        };

        fetchFilms();
    }, [currentUser, role]);

    const handleFilmClick = (film) => {
        setSelectedFilm(film);
        setIsVideoPlaying(false); // Reset video playing state
    };

    const handleCloseModal = () => {
        setSelectedFilm(null);
        setIsVideoPlaying(false); // Stop video playback when closing modal
    };

    const handlePosterClick = () => {
        setIsVideoPlaying(true); // Start playing video
    };

    const handleDeleteFilm = async () => {
        if (selectedFilm && selectedFilm.id) {
            try {
                // Delete the film document from Firestore
                const filmDocRef = doc(db, `${role}/${currentUser.uid}/films`, selectedFilm.id);
                await deleteDoc(filmDocRef);
                
                // Update local state
                setFilms(prevFilms => prevFilms.filter(film => film.id !== selectedFilm.id));
                handleCloseModal(); // Close the modal after deletion
            } catch (err) {
                console.error("Error deleting film:", err);
                setError("Failed to delete film. Please try again.");
            }
        }
    };

    return (
        <div id="filmgallery-layout">
            {error && <p id="error-message">{error}</p>}
          
                {films.map((film, index) => (
                    <div key={index} className="filmgallery-container" onClick={() => handleFilmClick(film)}>
                        <img
                            src={film.posterUrl}
                            className="filmposter-gallery"
                            alt={`Poster of ${film.name}`}
                        />
                        <p>{film.name}</p>
                    </div>
                ))}
            

            {selectedFilm && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div className="modal-content-film" onClick={(e) => e.stopPropagation()}>
                        <p className="close-modal" onClick={handleCloseModal}>x</p>
                         {/* <div className="modal-poster-container"> */}
                            {isVideoPlaying ? (
                                <video
                                    className="modal-video-player"
                                    controls
                                    autoPlay
                                    src={selectedFilm.filmUrl}
                                    onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking video
                                />
                            ) : (
                                <>
                                <div id="film-modal-poster">
                                    <img
                                        src={selectedFilm.posterUrl}
                                        className="modal-poster-film"
                                        alt={`Poster of ${selectedFilm.name}`}
                                        onClick={handlePosterClick}
                                    />
                                   
                                        <p id="video-playback-symbol" onClick={handlePosterClick}>&#9654;</p>
                                    </div>
                                    
                                </>
                            )}
                        {/* </div> */}
                        <h1 className="modal-filmtitle">{selectedFilm.name}</h1>
                        <p className="modal-film-description">
                        <span style={{ color: 'brown' }}>Description:</span> {selectedFilm.description}
                        </p>

                        <p className="modal-film-genre">
                            <span style={{ color: 'brown'}}> Genre: </span> {selectedFilm.genre}</p>
                        {/* <img src={deleteIcon} id="delete-film" onClick={handleDeleteFilm}/> */}
                   <button  id="deletefilm-button" onClick={handleDeleteFilm}>delete</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FilmGallery;