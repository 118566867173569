/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import NavBar from "../../components/navBar";
import { db } from "../../firebase/config";
import { collectionGroup, getDocs } from "firebase/firestore";
import { UserContext } from "../../firebase/userContext";
import { useNavigate } from "react-router-dom";
import './socialHome.css'
import logo from '../../assets/logos/logo1.png'

const SocialHome = () => {
    const { currentUser, role } = useContext(UserContext);
    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);
    const navigate = useNavigate();
   

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                // Reference to the content collection across users
                const contentRef = collectionGroup(db, 'content');
                const snapshot = await getDocs(contentRef);

                // Map through documents and store all necessary details
                const fetchedPhotos = snapshot.docs.map(doc => ({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    name: doc.data().name,
                    username: doc.data().username || 'Unknown',
                    description: doc.data().description || '',
                    uid: doc.ref.parent.parent.id // Get the UID of the user from the document path
                }));

                setPhotos(fetchedPhotos);
            } catch (err) {
                console.error("Error fetching photos:", err);
            }
        };

        fetchPhotos();
    }, [currentUser, role]);

    const handlePhotoClick = (photoId) => {
        const photo = photos.find(p => p.id === photoId);
        if (photo) {
            setSelectedPhoto(photo);
        } else {
            console.error("Photo not found in state!");
        }
    };

    const closeModal = () => {
        setSelectedPhoto(null);
    };

    return (
        <div id="socialhome-layout">
            <img src={logo} id="logo-profile" />
            <NavBar />
            <div id="socialhome-container">
                
                {photos.map(photo => (
                    
                    <img
                        key={photo.id}
                        src={photo.imageUrl}
                        alt="Uploaded"
                        className="socialphotos-home"
                        onClick={() => handlePhotoClick(photo.id)} // Handle photo click
                    />
                ))}
            </div>

            {/* Photo Modal */}
            {selectedPhoto && (
                <div className="modal-overlay" onClick={closeModal}>
                    <div className="modal-content-social" onClick={e => e.stopPropagation()}>
                        <span className="close-modal" onClick={closeModal}>&times;</span>
                        <img style={{cursor: 'pointer'}} onClick={() => navigate(`/creators/${selectedPhoto.username}`)} src={selectedPhoto.imageUrl} alt="Selected" className="modal-image-social" />
                        <h2  onClick={() => navigate(`/creators/${selectedPhoto.username}`)} className="socialmodal-name" style={{
                            color: 'white',
                            top: '10%',
                            cursor: 'pointer'
                        }}>{selectedPhoto.name}</h2>
                            <p className="socialmodal-username" onClick={() => navigate(`/creators/${selectedPhoto.username}`)}>@{selectedPhoto.username}</p>
                            <p className="socialmodal-description">{selectedPhoto.description}</p>
                           
                        
                    </div>
                </div>
            )}
        </div>
    );
};

export default SocialHome;