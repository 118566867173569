/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { db, collection, doc, getDocs, deleteDoc } from "../../../firebase/config";
import './socialGallery.css'

const SocialGallery = () => {
  const { currentUser } = useContext(UserContext);
  const [photos, setPhotos] = useState([]);
  const [selectedPhoto, setSelectedPhoto] = useState(null);

  useEffect(() => {
    if (currentUser) {
      const fetchSocialContent = collection(doc(db, 'creator', currentUser.uid), 'content');
      const fetchStoreContent = collection(doc(db, 'creator', currentUser.uid), 'store');

      const fetchData = async () => {
        const fetchedPhotos = [];

        // Fetch social content
        const socialDocs = await getDocs(fetchSocialContent);
        socialDocs.forEach((doc) => {
          const data = doc.data();
          if (data.imageUrl) {
            fetchedPhotos.push({
              id: doc.id,
              imageUrl: data.imageUrl,
              description: data.description,
              timestamp: data.timestamp,
              type: 'social', // To distinguish between social and store
            });
          }
        });

        // Fetch store content
        const storeDocs = await getDocs(fetchStoreContent);
        storeDocs.forEach((doc) => {
          const data = doc.data();
          if (data.imageUrl) {
            fetchedPhotos.push({
              id: doc.id,
              imageUrl: data.imageUrl,
              description: data.description,
              productName: data.productName,
              price: data.price,
              size: data.size,
              timestamp: data.timestamp,
              type: 'store', // To distinguish between social and store
            });
          }
        });

        // Sort combined data by timestamp in descending order (most recent first)
        fetchedPhotos.sort((a, b) => {
          if (a.timestamp && b.timestamp) {
            return b.timestamp.toDate() - a.timestamp.toDate(); // Sort by date descending
          }
          return 0;
        });

        setPhotos(fetchedPhotos);
      };

      fetchData().catch((error) => {
        console.error('Error fetching photos', error);
      });
    }
  }, [currentUser]);

  const handleDelete = async (photo) => {
    try {
      if (!photo.id) {
        console.error('Document ID is missing');
        return;
      }

      const collectionPath = photo.type === 'social' ? 'content' : 'store';
      const photoDocRef = doc(db, 'creator', currentUser.uid, collectionPath, photo.id);
      await deleteDoc(photoDocRef);

      setPhotos((prevPhotos) => prevPhotos.filter((p) => p.id !== photo.id));
      setSelectedPhoto(null);
    } catch (error) {
      console.error('Error deleting photo from Firestore', error);
    }
  };

  const handlePhotoClick = (photo) => {
    setSelectedPhoto(photo);
  };

  const handleCloseModal = () => {
    setSelectedPhoto(null);
  };

  const SocialModal = ({ photo, onClose }) => {
    if (!photo) return null;
    const formattedDate = photo.timestamp ? new Date(photo.timestamp.toDate()).toLocaleDateString() : '';

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content-social" onClick={(e) => e.stopPropagation()}>
          <img src={photo.imageUrl} alt="Selected" className="modal-image-social" />
          <p className="socialmodal-description">{photo.description}</p>
          <p className="socialmodal-timestamp">{formattedDate}</p>
          <span className="close-modal" onClick={onClose}>&times;</span>
          <button id="socialcontent-delete" onClick={() => handleDelete(photo)}>Delete</button>
        </div>
      </div>
    );
  };

  const StoreModal = ({ photo, onClose }) => {
    if (!photo) return null;

    return (
      <div className="modal-overlay" onClick={onClose}>
        <div className="modal-content-social" onClick={(e) => e.stopPropagation()}>
          <img src={photo.imageUrl} alt="Selected" className="modal-image-fashion" />
          <div id="modaldetails-store">
            <p ><span id="description-label">Description:</span> {photo.description}</p>
          </div>
          <p className="modalproduct-size-gallery"><span id="size-label">Size:</span> {photo.size}</p>
          <p className="modalproduct-name-gallery">{photo.productName}</p>
          <p className="modalproduct-price-gallery">Rs.{photo.price}</p>
          <span className="close-modal" onClick={onClose}>&times;</span>
          <button id="modaldelete-button" onClick={() => handleDelete(photo)}>Delete</button>
        </div>
      </div>
    );
  };

  return (
    <div id="socialgallery-layout">
      {photos.map((photo, index) => (
        <div key={index} className="socialgallery-photodetails">
          <img
            src={photo.imageUrl}
            id="socialgallery-photos"
            onClick={() => handlePhotoClick(photo)}
          />
          {photo.type === 'store' && (
            <div className="gallery-photodetails">
              <p>{photo.productName}</p>
              <p>Rs.{photo.price}</p>
            </div>
          )}
        </div>
      ))}
      {selectedPhoto && selectedPhoto.type === 'social' && (
        <SocialModal photo={selectedPhoto} onClose={handleCloseModal} />
      )}
      {selectedPhoto && selectedPhoto.type === 'store' && (
        <StoreModal photo={selectedPhoto} onClose={handleCloseModal} />
      )}
    </div>
  );
};

export default SocialGallery;