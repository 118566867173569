/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext, useEffect } from "react";
import './editProfile.css'
import { UserContext } from '../../firebase/userContext'
import logo from '../../assets/logos/logo1.png'
import settingIcon from '../../assets/icons/settingIcon.png'
import SocialOptions from '../../pages/profile/options/socialOptions'
import MusicOptions from '../../pages/profile/options/musicOptions'
import FilmOptions from '../../pages/profile/options/filmOptions'
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
    const { currentUser, updateProfile, error, cleanError } = useContext(UserContext);
    const [selectedCategory] = useState(currentUser?.category || '');
    const [selectedImage, setSelectedImage] = useState(null);
    const [imagePreview, setImagePreview] = useState(null);
    const [name, setName] = useState(currentUser?.name || '');
    const [username, setUsername] = useState(currentUser?.username || '');
    const [workEmail, setWorkEmail] = useState(currentUser?.workEmail || '');
    const [bio, setBio] = useState(currentUser?.bio || '');
    const [errorMessage, setError] = useState('');
    const [selectedOption, setSelectedOption] = useState(currentUser?.option || '')
    const navigate = useNavigate();

    useEffect(() => {
        setError('');
        cleanError();
    },[cleanError]);

    useEffect(() => {
        if (error) {
            setError(error);
        }
    }, [error]);

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    }

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setSelectedImage(file);
            setImagePreview(URL.createObjectURL(file)); // Create a preview URL for the selected image
        }
    };

    const handleImageUpload = () => {
        document.getElementById('fileInput').click();
    };

    const handleSaveProfile = () => {
        const profileData = {
            name,
            username,
            workEmail,
            bio,
            category: selectedCategory,
            option: selectedOption
        };
        updateProfile(profileData, selectedImage, username);
    };

    const categoryId = `category-${selectedCategory.toLowerCase()}`;


    
    
    
    
    return (
        <div id="editprofile-layout">
            <img src={logo} id="logo-profile" />
            <img src={settingIcon} id="setting-icon" onClick={() => navigate('/settings')}/>
           <div id="editprofile-container">
            <p id="edit-profile">edit profile</p>
            
            {/* PROFILE PHOTO  */}
            <div id="profilephoto-cover" onClick={handleImageUpload}>
                    {imagePreview ? ( // Use imagePreview to display the selected image
                        <img src={imagePreview} id="profilephoto-editprofile" alt="Selected Profile" />
                    ) : (
                        currentUser?.profileImageUrl ? (
                            <img src={currentUser.profileImageUrl} id="profilephoto-editprofile" alt="Current Profile" />
                        ) : (
                            <p>upload profile photo</p>
                        )
                    )}
                </div>

            <input
                    type="file"
                    id="fileInput"
                    style={{ display: 'none' }}
                    accept="image/*"
                    onChange={handleImageChange}
                />
            
            {/* NAME  */}
            <p id="name-label">name:</p>
            <input
            id="creatorname-editprofile" 
            value={name}
            onChange={(e) => setName(e.target.value)}
            />

            {/* USERNAME  */}
            <p id="username-label">username:</p>
            <input
            id="username-editprofile" 
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            />

            {/* BIO  */}
            <p id="bio-label"></p>
            <textarea id="bio-editprofile"
            placeholder="bio" 
            value={bio}
            onChange={(e) => setBio(e.target.value)}
            />

            {/* CATEGORY  */}
            <p id="category-label">category:</p>
            <div className="selectcategory-profile" id={categoryId}>
                    {selectedCategory === 'social' && <SocialOptions onChange={handleOptionChange}/>}
                    {selectedCategory === 'music' && <MusicOptions onChange={handleOptionChange} />}
                    {selectedCategory === 'film' && <FilmOptions onChange={handleOptionChange}/>}
                    
                </div>

            {/* WORK EMAIL  */}
              <p id="workemail-label">work email:</p>
            <input
            id="work-email" 
            value={workEmail}
            onChange={(e) => setWorkEmail(e.target.value)}
            />

           

            <button id="editprofile-button" onClick={handleSaveProfile}>save</button>
            {errorMessage && <p >{errorMessage}</p>}
           </div>
        </div>
    )
}

export default EditProfile;