/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../firebase/config";
import { useNavigate } from "react-router-dom";
import './storeUpload.css'
import NavBar from '../../../components/navBar'
import logo from '../../../assets/logos/logo1.png'

const StoreUpload = () => {
    const { currentUser, role } = useContext(UserContext);
    const [productPreview, setProductPreview] = useState(null);
    const [productFile, setProductFile] = useState(null);
    const [productName, setProductName] = useState('');
    const [productPrice, setProductPrice] = useState('');
    const [productSize, setProductSize] = useState('');
    const [productDescription, setProductDescription] = useState('')
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleProductImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setProductFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setProductPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('image-input').click();
    };

    const handleUpload = async () => {
        if (!productFile || !productName || !productPrice || !productSize || !productDescription) {
            setError("Please select an image and enter a description.");
            return;
        }

        try {
            // Upload image to Firebase Storage
            const storageRef = ref(storage, `fashion-gallery/${currentUser.uid}/${productFile.name}`);
            await uploadBytes(storageRef, productFile);
            const downloadURL = await getDownloadURL(storageRef);

            // Save image data and description in Firestore under the user's sub-collection
            const contentRef = collection(db, `${role}/${currentUser.uid}/store`);
            await addDoc(contentRef, {
                imageUrl: downloadURL,
                description: productDescription,
                productName: productName,
                size: productSize,
                username: currentUser.username,
                price: productPrice,
                name: currentUser.name,
                timestamp: serverTimestamp(),
            });

            // Clear the form after successful upload
            setProductPreview(null);
            setProductFile(null);
            setProductDescription("");
            setProductName('');
            setProductSize('');
            setProductPrice('');
            setError("");
            navigate(`/creator/${currentUser.username}`)
        } catch (err) {
            console.error("Error uploading image:", err);
            setError("Failed to upload image. Please try again.");
        }
    };

    return (
        <div id="storeupload-layout">
             <NavBar />
             <img src={logo} id="logo-profile" />
              <div id="storeupload-container">
                <p id="upload-product">upload product</p>

                <div id="uploadimage-store" onClick={triggerFileInput}>
                    {productPreview ? (
                        <img src={productPreview} id="uploadimageproduct-preview" />
                    ) : (
                        <p>upload photos</p>
                    )}
                </div>

                <input
                    id="image-input"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleProductImageChange}
                />
                <p id="productname-label-upload">product name:</p>
                <input
                
                id='productname-input'
                value={productName}
                onChange={(e) => setProductName(e.target.value)}

                />
                 <p id="productprice-label-upload">price:</p>
                <input
                
                id='productprice-input'
                value={productPrice}
                onChange={(e) => setProductPrice(e.target.value)} 
                />

               <p id="productsize-label-upload">size:</p>
                <input
                
                id='productsize-input' 
                value={productSize}
                onChange={(e) => setProductSize(e.target.value)}
                />

               <textarea
                placeholder="description"
                id='productdescription-upload'
                value={productDescription}
                onChange={(e) => setProductDescription(e.target.value)} 
                />

                      {error && <p >{error}</p>}

                <button id="productupload-button" onClick={handleUpload}>upload</button>
              </div>
        </div>
    )
}

export default StoreUpload;
