import React from "react";
import { useNavigate } from "react-router-dom";

const Terms = () => {
    const navigate = useNavigate();

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh'
        }}> 
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: '70%',
            maxWidth: '400px',
            height: '70vh',
            maxHeight: '400px',
            position: 'absolute',
            padding: '20px',
            backgroundColor: 'brown',
            borderRadius: '10px'
        }}>
            <h1>terms</h1>
            <p>Welcome to Jussst.com <br /> <br />This is a Minimum Viable Product, and all 
                content is for sample purposes. We request users to maintain confidentiality
                regarding the user experience and design. 
                 <br /> <br /> Thank You!!
            </p>
        </div>
        <button 
        onClick={() => navigate('/register')}
        style={{
            position: 'absolute',
            bottom: '10%'
        }}>back</button>
        </div>
    )


}

export default Terms;