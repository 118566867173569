import React from "react";
import { useParams } from "react-router-dom";
import SocialUpload from "./socialUpload";
import MusicUpload from "./musicUpload";
import FilmUpload from "./filmUpload";

const UploadHandler = () => {
  const { category } = useParams();

  const renderUploadPage = () => {
    switch (category) {
      case 'social':
        return <SocialUpload />;
    
     
        case 'music':
        return <MusicUpload />;
         
        case 'film':
          return <FilmUpload />

      default:
        return <div>Invalid category</div>;
    }
  };

  return <div>{renderUploadPage()}</div>;
};

export default UploadHandler;