/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../firebase/config";
import FilmUploadOption from "./filmUploadOptions";
import { useNavigate } from "react-router-dom";
import './filmUpload.css'
import logo from '../../../assets/logos/logo1.png'
import NavBar from '../../../components/navBar'

const FilmUpload = () => {
    const { currentUser, role } = useContext(UserContext);
    const [posterFile, setPosterFile] = useState(null);
    const [filmFile, setFilmFile] = useState(null);
    const [filmPreview, setFilmPreview] = useState(null);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [genre, setGenre] = useState(""); // Genre state
    const [posterPreview, setPosterPreview] = useState(null);
    const [error, setError] = useState("");
    const navigate = useNavigate();

    const handlePosterClick = () => {
        document.getElementById('film-poster-input').click();
    };

    

    const handlePosterChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setPosterFile(file);
            setPosterPreview(URL.createObjectURL(file));
        }
    };

    const handleFilmChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            // Validate film file duration here if needed
            setFilmFile(file);
            setFilmPreview(URL.createObjectURL(file))
            // event.target.value = "";
        }
    };

    const handleUpload = async () => {
        console.log("Poster File:", posterFile);
        console.log("Film File:", filmFile);
        console.log("Name:", name);
        console.log("Description:", description);
        console.log("Genre:", genre);

        if (!posterFile || !filmFile || !name || !description || !genre) {
            setError("Please fill in all fields and select files.");
            return;
        }

        try {
            // Upload poster to Firebase Storage
            const posterRef = ref(storage, `film-gallery/${currentUser.uid}/${posterFile.name}`);
            await uploadBytes(posterRef, posterFile);
            const posterURL = await getDownloadURL(posterRef);

            // Upload film to Firebase Storage
            const filmRef = ref(storage, `film-gallery/${currentUser.uid}/${filmFile.name}`);
            await uploadBytes(filmRef, filmFile);
            const filmURL = await getDownloadURL(filmRef);

            // Save film data in Firestore
            const filmsRef = collection(db, `${role}/${currentUser.uid}/films`);
            await addDoc(filmsRef, {
                name: name,
                description: description,
                genre: genre,
                posterUrl: posterURL,
                filmUrl: filmURL,
                userId: currentUser.uid,
                username: currentUser.username,
                timestamp: serverTimestamp()
            });

            // Clear the form after successful upload
            setPosterPreview(null);
            setPosterFile(null);
            setFilmFile(null);
            setName("");
            setDescription("");
            setGenre("");
            setError("");
            navigate(`/creator/${currentUser.username}`);
        } catch (err) {
            console.error("Error uploading film:", err);
            setError("Failed to upload film. Please try again.");
        }
    };

    return (
        <div id="filmupload-layout">
            <img src={logo} id="logo-profile" />
            <NavBar />
            <div id="filmupload-container">
                <p id="film-upload">upload a short film</p>
                
                <div 
                    id="filmposter-upload"
                    onClick={handlePosterClick}
                >
                    {posterPreview ? (
                        <img
                            id="filmposter-preview"
                            src={posterPreview}
                            alt="Selected Film Poster"
                        />
                    ) : (
                        <p>upload a poster</p>
                    )}
                </div>
                
                <input
                    type="file"
                    id="film-poster-input"
                    style={{ display: 'none' }} // Hide file input
                    onChange={handlePosterChange}
                />

                <input
                    type="file"
                    id="filmupload-input"
                    accept="video/mp4"
                    onChange={handleFilmChange}
                />



                     {filmPreview && (
                        <div id="filmpreview-container">
                    <video
                        id="film-preview"
                        controls
                        src={filmPreview}
                        // style={{ width: '100%', marginTop: '10px' }}
                    >
                        Your browser does not support the video tag.
                    </video>
                    {/* <button id="close-preview-button" onClick={handleClosePreview}>
                            Close Preview
                        </button> */}
                    </div>
                )}

                <input
                    placeholder="name of the film"
                    id="filmupload-name" 
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                />

                <textarea
                    id="filmupload-description" 
                    placeholder="description "
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                />

                <FilmUploadOption
                    className='filmupload-options'
                    selectedGenre={genre}
                    onGenreChange={(selectedGenre) => setGenre(selectedGenre)}
                />

                {error && <p id="upload-error-message">{error}</p>}

                <button id="filmupload-button" onClick={handleUpload}>
                    Upload
                </button>
            </div>
        </div>
    );
}

export default FilmUpload;