/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from "react";
import { UserContext } from "../../../firebase/userContext";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { db, storage } from "../../../firebase/config";
import './socialUpload.css';
import { useNavigate } from "react-router-dom";
import StoreUpload from "./storeUpload";
import NavBar from '../../../components/navBar'
import logo from '../../../assets/logos/logo1.png'

const SocialUpload = () => {
    const { currentUser, role } = useContext(UserContext);
    const [imagePreview, setImagePreview] = useState(null);
    const [imageFile, setImageFile] = useState(null);
    const [description, setDescription] = useState("");
    const [error, setError] = useState("");
    const navigate = useNavigate();
    const [showStoreUpload, setShowStoreUpload] = useState(false)

    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const triggerFileInput = () => {
        document.getElementById('image-input').click();
    };

    const handleUpload = async () => {
        if (!imageFile || !description) {
            setError("Please select an image and enter a description.");
            return;
        }

        try {
            // Upload image to Firebase Storage
            const storageRef = ref(storage, `social-gallery/${currentUser.uid}/${imageFile.name}`);
            await uploadBytes(storageRef, imageFile);
            const downloadURL = await getDownloadURL(storageRef);

            // Save image data and description in Firestore under the user's sub-collection
            const contentRef = collection(db, `${role}/${currentUser.uid}/content`);
            await addDoc(contentRef, {
                imageUrl: downloadURL,
                description: description,
                username: currentUser.username,
                timestamp: serverTimestamp(),
                name: currentUser.name
            });

            // Clear the form after successful upload
            setImagePreview(null);
            setImageFile(null);
            setDescription("");
            setError("");
            navigate(`/creator/${currentUser.username}`)
        } catch (err) {
            console.error("Error uploading image:", err);
            setError("Failed to upload image. Please try again.");
        }
    };

    if (showStoreUpload) {
        return <StoreUpload />
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    return (
        <div id="upload-layout">
            <NavBar />
            <img src={logo} id="logo-profile" />
           <div id="socialupload-container">
            <p id="upload-content">upload content</p>
            
            <div id="contentphoto-upload" onClick={triggerFileInput}>
                {imagePreview ? (
                    <img src={imagePreview} id="contentphoto-preview" />
                ) : (

                <p>upload photo</p>
                )}
            </div>
            
            <input
                    id="image-input"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={handleImageChange}
                />

            <textarea
            placeholder="description"
            id="socialupload-description" 
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            />
            <button id="uploadbutton-social" onClick={handleUpload}>upload</button>
            {error && <p id="upload-error-message">{error}</p>}
           </div>
           <p id='uploadproduct-link' onClick={() => setShowStoreUpload(true)}>
                    upload product
                </p>
        </div>
    )
}

export default SocialUpload;