import React from "react";
import OtherUserSG from "./ouSocialGallery";

import OtherUserMG from "./ouMusicGallery";
import OtherUserFilmGallery from "./ouFilmGallery";

const GalleryHandler = ({ category, role, uid }) => {
    const getGalleryComponent = () => {
        if (category) {
            const lowerCaseCategory = category.toLowerCase();
            switch (lowerCaseCategory) {
                case 'social':
                    return <OtherUserSG role={role} uid={uid} />;
                    
                         case 'music':
                            return <OtherUserMG role={role} uid={uid} />
                            case 'film':
                                return <OtherUserFilmGallery role={role} uid={uid} />
                default:
                    return <div>No gallery available for this category.</div>;
            }
        } else {
            return <div>No category specified.</div>;
        }
    };

    return getGalleryComponent();
};

export default GalleryHandler;