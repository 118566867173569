import React from "react";

const FilmUploadOption = ({ selectedGenre, onGenreChange, className }) => {
    const handleChange = (event) => {
        onGenreChange(event.target.value);
    };

    return (
        <div>
            <select 
                className={className}  // Adding className here
                value={selectedGenre} 
                onChange={handleChange}
            >
                <option value="">Please select</option>
                <option value="Documentary">Documentary</option>
                <option value="Comedy">Comedy</option>
                <option value="Horror">Horror</option>
                <option value="Romance">Romance</option>
                <option value="Sci-fi">Sci-fi</option>
                <option value="True Story">True Story</option>
            </select>
        </div>
    );
};

export default FilmUploadOption;
