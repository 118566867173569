/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import NavBar from "../../../components/navBar";
import { db } from "../../../firebase/config";
import { collection, query, where, getDocs, doc, setDoc, deleteDoc, getDoc } from "firebase/firestore";
import './otherUser.css';

import settingIcon from '../../../assets/icons/settingIcon.png'
import messageIcon from '../../../assets/icons/messageIcon.png'


import GalleryHandler from "./galleryHandler";
import { UserContext } from "../../../firebase/userContext";

const OtherUser = () => {
    const { username } = useParams();
    const { currentUser, role } = useContext(UserContext);
    const [otherUserData, setOtherUserData] = useState(null);
    const [isFollowing, setIsFollowing] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            if (!username) {
                console.error("Username is not defined");
                return;
            }

            try {
                const userQuery = query(
                    collection(db, "creator"), // Replace with the correct collection if needed
                    where("username", "==", username)
                );

                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    // Assuming there's only one document with the matching username
                    const userDoc = querySnapshot.docs[0];
                    setOtherUserData(userDoc.data());

                    // Check if the current user is following this user
                    if (currentUser) {
                        const followingDocRef = doc(db, `${role}/${currentUser.uid}/following/${userDoc.id}`);
                        const followingDoc = await getDoc(followingDocRef);
                        setIsFollowing(followingDoc.exists());
                    }
                } else {
                    console.log("No such user found!");
                }
            } catch (error) {
                console.error("Error fetching user data: ", error);
            }
        };

        fetchUserData();
    }, [username, currentUser, role]);

    const handleFollowToggle = async () => {
        if (!currentUser || !otherUserData) return;

        const otherUserRef = doc(db, `${otherUserData.role}/${otherUserData.uid}`);
        const currentUserRef = doc(db, `${role}/${currentUser.uid}`);

        if (isFollowing) {
            // Unfollow logic
            await deleteDoc(doc(otherUserRef, `followers/${currentUser.uid}`));
            await deleteDoc(doc(currentUserRef, `following/${otherUserData.uid}`));
            setIsFollowing(false);
        } else {
            // Follow logic
            await setDoc(doc(otherUserRef, `followers/${currentUser.uid}`), { username: currentUser.username });
            await setDoc(doc(currentUserRef, `following/${otherUserData.uid}`), { username: otherUserData.username });
            setIsFollowing(true);
        }
    };

    const toggleEmailPopup = () => {
        setShowEmail(!showEmail);
    }
    

    return (
        <div id="creator-layout">
            <img src={settingIcon}  onClick={() => navigate('/settings')} id="setting-icon" alt="Settings" />
            <img src={messageIcon} id="message-icon" onClick={toggleEmailPopup}/>
            <NavBar />
            {otherUserData && (
                <>
                    <div id="creator-profilephoto">
                        <img src={otherUserData.profileImageUrl} id="profilephoto" />
                    </div>
                    <h2 id="creator-name">{otherUserData.name}</h2>
                    <p id="creator-category">{otherUserData.option}</p>
                    <p id="creator-username">@{otherUserData.username}</p>
                    <p id="creator-bio">{otherUserData.bio}</p>
                    <button id="editprofile-creatorbutton" onClick={handleFollowToggle}>
                        {isFollowing ? 'Following' : 'Follow'}
                    </button>

                    <GalleryHandler
                        category={otherUserData.category}
                        role={otherUserData.role}  // Pass the role
                        uid={otherUserData.uid}    // Pass the UID
                    />
                </>
            )}
             {showEmail && (
            <div id="email-popup">
                <p>{otherUserData?.workEmail || "not available"} </p>
                <button  id="emailpop-button" onClick={toggleEmailPopup}>x</button>
            </div>
           )}

        </div>
    );
}

export default OtherUser;
