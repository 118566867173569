/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/config";
import { collection, query, getDocs, doc } from "firebase/firestore";

const OtherUserSG = ({ role, uid }) => {
    const [photos, setPhotos] = useState([]);
    const [selectedPhoto, setSelectedPhoto] = useState(null);

    useEffect(() => {
        const fetchPhotos = async () => {
            try {
                // Fetch content from 'content' (social) sub-collection
                const contentRef = collection(doc(db, role, uid), 'content');
                const contentSnapshot = await getDocs(query(contentRef));

                const socialPhotos = contentSnapshot.docs.map(doc => ({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    description: doc.data().description,
                    timestamp: doc.data().timestamp,
                    type: 'social', // To distinguish between social and store
                }));

                // Fetch content from 'store' (store) sub-collection
                const storeRef = collection(doc(db, role, uid), 'store');
                const storeSnapshot = await getDocs(query(storeRef));

                const storePhotos = storeSnapshot.docs.map(doc => ({
                    id: doc.id,
                    imageUrl: doc.data().imageUrl,
                    description: doc.data().description,
                    productName: doc.data().productName,
                    price: doc.data().price,
                    size: doc.data().size,
                    timestamp: doc.data().timestamp,
                    type: 'store', // To distinguish between social and store
                }));

                // Combine and sort photos by timestamp
                const allPhotos = [...socialPhotos, ...storePhotos].sort((a, b) => {
                    if (a.timestamp && b.timestamp) {
                        return b.timestamp.toDate() - a.timestamp.toDate();
                    }
                    return 0;
                });

                setPhotos(allPhotos);
            } catch (err) {
                console.error("Error fetching photos:", err);
            }
        };

        fetchPhotos();
    }, [role, uid]);

    const handlePhotoClick = (photo) => {
        setSelectedPhoto(photo);
    };

    const handleCloseModal = () => {
        setSelectedPhoto(null);
    };

    // Modal for social (content)
    const SocialModal = ({ photo, onClose }) => {
        if (!photo) return null;

        const formattedDate = photo.timestamp 
            ? new Date(photo.timestamp.toDate()).toLocaleDateString()
            : '';

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content-social" onClick={(e) => e.stopPropagation()}>
                    <img src={photo.imageUrl} alt="Selected" className="modal-image-social" />
                    <p className="socialmodal-description">{photo.description}</p>
                    <p className="socialmodal-timestamp">{formattedDate}</p>
                    <span className="close-modal" onClick={onClose}>&times;</span>
                </div>
            </div>
        );
    };

    // Modal for store (fashion)
    const StoreModal = ({ photo, onClose }) => {
        if (!photo) return null;

        return (
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal-content-social" onClick={(e) => e.stopPropagation()}>
                    <img src={photo.imageUrl} alt="Selected" className="modal-image-fashion" />
                    <div id="modaldetails-store">
                        <p><span id="description-label">Description:</span> {photo.description}</p>
                    </div>
                    <p className="modalproduct-size-gallery"><span style={{color: 'brown'}} id="size-label">Size:</span> {photo.size}</p>
                    <p className="modalproduct-name-gallery">{photo.productName}</p>
                    <p className="modalproduct-price-gallery">Rs.{photo.price}</p>
                    <span className="close-modal" onClick={onClose}>&times;</span>
                    <button id="modaldelete-button" >Buy</button>
                </div>
            </div>
        );
    };

    return (
        <div id="socialgallery-layout">
            {photos.map((photo, index) => (
                <div key={index} className="socialgallery-photodetails">
                    <img
                        src={photo.imageUrl}
                        id="socialgallery-photos"
                        onClick={() => handlePhotoClick(photo)}
                    />
                    {photo.type === 'store' && (
                        <div className="gallery-photodetails">
                            <p>{photo.productName}</p>
                            <p>Rs.{photo.price}</p>
                        </div>
                    )}
                </div>
            ))}
            {selectedPhoto && selectedPhoto.type === 'social' && (
                <SocialModal photo={selectedPhoto} onClose={handleCloseModal} />
            )}
            {selectedPhoto && selectedPhoto.type === 'store' && (
                <StoreModal photo={selectedPhoto} onClose={handleCloseModal} />
            )}
        </div>
    );
};

export default OtherUserSG;