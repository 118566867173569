/* eslint-disable no-duplicate-case */
import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db, storage, doc, setDoc, collection, query, where, getDocs,  getDoc, deleteDoc } from './config'; // Import Firestore functions
import { createUserWithEmailAndPassword, deleteUser, signInWithEmailAndPassword } from 'firebase/auth';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import SocialGallery from '../pages/profile/gallery/socialGallery';

import MusicGallery from '../pages/profile/gallery/musicGallery';
import FilmGallery from '../pages/profile/gallery/filmGallery';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [role, setRole] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleRoleSelection = (selectedRole) => {
        setRole(selectedRole);
        setError(''); // Clear error when role is selected
    };

    const handleLogin = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Fetch the user's role from Firestore
            const creatorDoc = await getDoc(doc(db, 'creator', user.uid));
            const explorerDoc = await getDoc(doc(db, 'explorer', user.uid));

            let userRole = '';
            let userData = {};

            if (creatorDoc.exists()) {
                userRole = 'creator';
                userData = creatorDoc.data();
            } else if (explorerDoc.exists()) {
                userRole = 'explorer';
                userData = explorerDoc.data();
            } else {
                throw new Error('User role not found');
            }

            setCurrentUser({ uid: user.uid, ...userData });
            setRole(userRole);

            // Navigate to the appropriate page based on the role
            if (userRole === 'creator') {
                navigate(`/creator/${userData.username}`);
            } else if (userRole === 'explorer') {
                navigate(`/home/${userData.username}`);
            }
        } catch (error) {
            if (error.code === 'auth/user-not-found' || error.code === 'auth/wrong-password') {
                setError('Invalid email or password');
            } else {
                setError('Login failed. Please try again.');
            }
            console.error('Error logging in:', error);
        }
    };

    const handleRegister = async () => {
       let errorMessage;

        if (!role || !email || !password) {
            errorMessage = ('Please fill in the role, email, and password');
            setError(errorMessage)
            return errorMessage;
        }

        try {
            // Create user with email and password using Firebase Authentication
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            // Create user record in Firestore under the correct role collection
            const newUser = {
                email: user.email,
                role,
                createdAt: new Date(),
            };

            await setDoc(doc(db, role, user.uid), newUser);
            setCurrentUser({ uid: user.uid, ...newUser });

            if (role === 'creator') {

                navigate('/category');
            } else if (role === 'explorer') {
                navigate('/username')
            }

            return null;
        } catch (error) {
               errorMessage = 'Registration failed. Please try again.';
            if (error.code === 'auth/email-already-in-use') {
                errorMessage = 'Email is already registered';
            }
            setError(errorMessage);
            console.error('Error registering user:', error);
            return errorMessage; // Return the error
        }
    };

    

    const handleCategoryAndUsername = async (selectedCategory, username) => {
        if (!selectedCategory || !username) {
            setError('Please select a category and enter a username');
            return 'Please select a category and enter a username'; 
        }
    
        try {
            // Define the collections to check
            const collectionsToCheck = [role, 'creator', 'explorer'];
            let isUsernameTaken = false;
    
            // Check if the username is unique across the specified collections
            for (const collectionName of collectionsToCheck) {
                const roleCollection = collection(db, collectionName);
                const q = query(roleCollection, where('username', '==', username));
                const querySnapshot = await getDocs(q);
    
                if (!querySnapshot.empty) {
                    isUsernameTaken = true;
                    // console.log('Username is taken');
                  
                    break;
                }
            }
            if (isUsernameTaken) {
                const errorMsg = 'Username is already taken';
                setError(errorMsg);
                return errorMsg; // Return the error
            }
           
            await setDoc(doc(db, role, currentUser.uid), {
                ...currentUser,
                category: selectedCategory,
                username,
            });
    
            setCurrentUser((prev) => ({ ...prev, category: selectedCategory, username }));
    
            if (role === 'creator') {

                navigate(`/edit-profile/${username}`);
            } else if (role === 'explorer') {
                navigate('/home')
            }
        } catch (error) {
            console.error('Error creating user profile:', error);
          
            const errorMsg = 'Failed to create profile. Please try again.';
        setError(errorMsg);
        return errorMsg; // Return the error
        }
    };

    const handleExplorerUsername = async (username, name) => {
        if (!username || !name) {
            const errorMsg = 'Please enter both name and username'
            setError(errorMsg);
            return errorMsg;
        }

        try {
            const collectionsToCheck = ['explorer', 'creator'];
            let isUsernameTaken = false;

            for (const collectionName of collectionsToCheck) {
                const roleCollection = collection(db, collectionName);
                const q = query(roleCollection, where('username', '==', username));
                const querySnapshot = await getDocs(q);

                if (!querySnapshot.empty) {
                    isUsernameTaken = true;
                    break;
                }
            }

            if (isUsernameTaken) {
                const errorMsg = 'Username is already taken';
                setError(errorMsg);
                return errorMsg;
            }

            await setDoc(doc(db, role, currentUser.uid), {
                ...currentUser,
                username,
                name,
            });

            setCurrentUser((prev) => ({ ...prev, username, name }));

            navigate(`/home/${username}`);
        } catch (error) {
            const errorMsg = 'Failed to create profile. Please try again.';
            setError(errorMsg);
            return errorMsg;
        }
    };

    const updateProfile = async (profileData, profileImage, newUsername) => {
        try {
            if (newUsername && newUsername !== currentUser.username) {
                // Define the collections to check
                const collectionsToCheck = [role, 'creator', 'explorer'];
                let isUsernameTaken = false;

                // Check if the new username is unique across the specified collections
                for (const collectionName of collectionsToCheck) {
                    const roleCollection = collection(db, collectionName);
                    const q = query(roleCollection, where('username', '==', newUsername));
                    const querySnapshot = await getDocs(q);

                    if (!querySnapshot.empty) {
                        isUsernameTaken = true;
                        break;
                    }
                }

                if (isUsernameTaken) {
                    setError('Username is already taken');
                    return;
                }
            }

            let profileImageUrl = null;

            if (profileImage) {
                const imageRef = ref(storage, `profile_images/${currentUser.uid}`);
                await uploadBytes(imageRef, profileImage);
                profileImageUrl = await getDownloadURL(imageRef);
            }

            const updateData = {
                ...currentUser,
                ...profileData,
                profileImageUrl: profileImageUrl || currentUser.profileImageUrl,
                username: newUsername || currentUser.username,
            };

            await setDoc(doc(db, role, currentUser.uid), updateData);

            setCurrentUser(updateData);

            navigate(`/creator/${updateData.username}`);
        } catch (error) {
            console.error('Error updating profile:', error);
            alert('Failed to update profile');
        }
    };
    
    const cleanError = () => setError('');

    const navigateToUpload = () => {
        if (currentUser && currentUser.category) {
            navigate(`/upload/${currentUser.category}`);
        } else {
            console.error("User category is not set.");
            setError("Please select a category first.");
        }
    };

    const getGalleryComponent = () => {
        if (currentUser && currentUser.category) {
            const category = currentUser.category.toLowerCase();
            switch (category) {
               
                case 'social':
                    return <SocialGallery />;
                   
                        case 'music':
                        return <MusicGallery />;
                        case 'film':
                            return <FilmGallery />
                            
                default:
                    return <div>No gallery available for this category.</div>;
            }
        } else {
            return <div>Please select a category.</div>;
        }
    };
   
   
    const handleDeleteAccount = async () => {
        if (!currentUser) return;
    
        try {
            // Function to delete all documents and their sub-collections
            const deleteDocumentAndSubCollections = async (docRef) => {
                // Handle 'content' sub-collection
                const contentRef = collection(docRef, 'content');
                const contentSnapshot = await getDocs(contentRef);
                for (const contentDoc of contentSnapshot.docs) {
                    await deleteDoc(contentDoc.ref);
                }
    
                // Handle 'store' sub-collection
                const storeRef = collection(docRef, 'store');
                const storeSnapshot = await getDocs(storeRef);
                for (const storeDoc of storeSnapshot.docs) {
                    await deleteDoc(storeDoc.ref);
                }

                 // Handle 'followers' sub-collection
                 const followersRef = collection(docRef, 'followers');
                 const followersSnapshot = await getDocs(followersRef);
                 for (const followersDoc of followersSnapshot.docs) {
                     await deleteDoc(followersDoc.ref);
                 }

                 // Handle 'following' sub-collection
                 const followingRef = collection(docRef, 'following');
                 const followingSnapshot = await getDocs(followingRef);
                 for (const followingDoc of followingSnapshot.docs) {
                     await deleteDoc(followingDoc.ref);
                 }
    
                // Delete the main document itself
                await deleteDoc(docRef);
            };
    
            // Collections to delete from Firestore
            const collectionsToDelete = ['creator', 'explorer'];
    
            for (const collectionName of collectionsToDelete) {
                const colRef = collection(db, collectionName);
                const q = query(colRef, where('uid', '==', currentUser.uid));
                const snapshot = await getDocs(q);
    
                for (const docSnapshot of snapshot.docs) {
                    const docRef = docSnapshot.ref;
                    await deleteDocumentAndSubCollections(docRef);
                }
            }
    
            // Delete the user from Firebase Authentication
            await deleteUser(auth.currentUser);
    
            // Clear current user state and redirect to home or login page
            setCurrentUser(null);
            navigate('/'); // Redirect to home or login page after account deletion
        } catch (error) {
            console.error('Error deleting account:', error);
            alert('Failed to delete account. Please try again.');
        }
    };
    
    
    

    return (
        <UserContext.Provider
            value={{
                currentUser,
                role,
                setRole: handleRoleSelection,
                setEmail,
                setPassword,
                handleRegister,
                handleCategoryAndUsername,
                error,
                updateProfile,
                cleanError,
                navigateToUpload,
                getGalleryComponent,
                handleExplorerUsername,
                handleDeleteAccount,
                handleLogin
            }}
        >
            {children}
        </UserContext.Provider>
    );
};
