/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import './home.css'
import NavBar from "../../components/navBar";
import { useNavigate, useParams } from "react-router-dom";
import logo from '../../assets/logos/logo1.png'



const Home = () => {
    const navigate = useNavigate();
    const { username } = useParams();

    
    
    return (
        <div id="home-layout">
            <img src={logo} id="logo-profile" />
            <NavBar />
            <div id="home-container">
            <h1 id="social-home" onClick={() => navigate(`/social/${username}`)}>social</h1>
            <h1 id="store-home" onClick={() => navigate(`/store/${username}`)}>store</h1>
            <h1 id="music-home" onClick={() => navigate(`/music/${username}`)}>music</h1>
            <h1 id="film-home" onClick={() => navigate(`/films/${username}`)}>films</h1>
           </div>
        </div>
    )
}

export default Home;