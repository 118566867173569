import React, { useEffect, useState } from "react";
import { db } from "../../../firebase/config";
import { collection, getDocs } from "firebase/firestore";
import MusicPlayer from "../../../components/musicPlayer";

const OtherUserMG = ({ role, uid }) => {
    const [tracks, setTracks] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState("");
    const [playingTrackId, setPlayingTrackId] = useState(null);

    useEffect(() => {
        const fetchMusic = async () => {
            setLoading(true);
            setError("");

            try {
                // Fetching tracks from the specific Firestore path: role/uid/music/singles/tracks
                const tracksCollectionRef = collection(db, `${role}/${uid}/music/singles/tracks`);
                const tracksSnapshot = await getDocs(tracksCollectionRef);

                // Map through the documents and retrieve necessary fields
                const fetchedTracks = tracksSnapshot.docs.map(doc => ({
                    id: doc.id,
                    title: doc.data().title,
                    fileURL: doc.data().fileURL,
                    coverURL: doc.data().coverURL,
                    timestamp: doc.data().timestamp
                }));

                const sortedTracks = fetchedTracks.sort((a, b) => b.timestamp?.toDate() - a.timestamp?.toDate());
                setTracks(sortedTracks);
            } catch (err) {
                console.error("Error fetching tracks:", err);
                setError("Failed to load tracks. Please try again.");
            } finally {
                setLoading(false);
            }
        };

        fetchMusic();
    }, [role, uid]);

    const handlePlay = (trackId) => {
        if (playingTrackId !== trackId) {
            setPlayingTrackId(trackId); // Set the playing track ID
        }
    };

    return (
        <div id="musicgallery-layout">
            {loading && <p>Loading tracks...</p>}
            {error && <p>{error}</p>}
            {!loading && !error && tracks.length > 0 && (
                <ul>
                    {tracks.map(track => (
                        <li key={track.id} className="trackitem-container">
                            <img
                                src={track.coverURL}
                                alt={`${track.title} cover`}
                                className="track-cover"
                            />
                            <div className="track-info">
                                <p>{track.title}</p>
                                <MusicPlayer
                                    fileURL={track.fileURL}
                                    className="custom-music-player"
                                    isPlaying={playingTrackId === track.id}
                                    onPlay={() => handlePlay(track.id)}
                                    onPause={() => { if (playingTrackId === track.id) setPlayingTrackId(null); }}
                                />
                            </div>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default OtherUserMG;