/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState } from "react";
import './creator.css'
import { UserContext } from '../../firebase/userContext';
import { useNavigate } from "react-router-dom";
import NavBar from "../../components/navBar";
import settingIcon from '../../assets/icons/settingIcon.png'
import messageIcon from '../../assets/icons/messageIcon.png'
import uploadIcon from '../../assets/icons/uploadIcon.png'

const Creator = () => {
    const { currentUser, navigateToUpload, getGalleryComponent } = useContext(UserContext);
    const navigate = useNavigate(); 
    const [showEmail, setShowEmail] = useState(false);

    const toggleEmailPopup = () => {
        setShowEmail(!showEmail);
    }


    return (
        <div id="creator-layout">
            <img src={settingIcon} id="setting-icon" onClick={() => navigate('/settings')}/>
            <img src={messageIcon} id="message-icon" onClick={toggleEmailPopup} />
            <img src={uploadIcon} id="upload-icon" onClick={navigateToUpload}/>
            <NavBar />

        <h2 id="creator-name">{currentUser?.name || ''} </h2>
        <p id="creator-username">@{currentUser?.username || ''} </p>
        <p id="creator-category">{currentUser?.option || ''} </p>

        <div id="creator-profilephoto">
            {currentUser?.profileImageUrl && (
                <img src={currentUser.profileImageUrl} id="profilephoto" />
            )}
        </div>
         <div id="creator-bio"> 
           <p>{currentUser?.bio || ''} </p>
            </div>
        <button id="editprofile-creatorbutton" onClick={() => navigate(`/edit-profile/${currentUser?.username}`)}>edit profile</button>
        {getGalleryComponent()}
        {showEmail && (
            <div id="email-popup">
               <p>
                <span style={{ color: 'brown' }}>email:</span> {currentUser?.workEmail || "not available"}
                </p>

                <button  id="emailpop-button" onClick={toggleEmailPopup}>x</button>
            </div>
           )}

        </div>
    )
}

export default Creator;