/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect} from "react";
import { UserContext } from "../../firebase/userContext";
import './register.css'
import logo from '../../assets/logos/logo1.png'
import { useNavigate } from "react-router-dom";

const Register = () => {
    const { role, setRole, setEmail, setPassword, handleRegister, error} = useContext(UserContext);
    const [localError, setLocalError] = useState('');
    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setLocalError('');
    }, [error]);

    const handleInputChange = (e) => {
      const { id, value } = e.target;

      setLocalError('');
      if (id === 'email') {
        setEmail(value);
      } else if (id === 'password') {
        setPassword(value)
      }
    };

    const handleRegisterClick = async () => {
        setLocalError('');

        if (!isCheckboxChecked) {
            setLocalError('Please agree to the terms');
            return;
        }

        const registrationError = await handleRegister();
        if (registrationError) {
            setLocalError(registrationError)
        }
    };

    const handleCheckbox = (e) => {
        setIsCheckboxChecked(e.target.checked);
    }

    useEffect(() => {
        if (error & error !== localError) {
            setLocalError(error);
        }
    }, [error, localError]);




    return (
        <div id="register-layout">
            <img src={logo} id="logo" /> 
           <div id="register-container">
            <p id="create-account">create an account</p>
            
            <h1 
            id="creator"
            className={role === 'creator' ? 'selected' : '' }
            onClick={() => setRole('creator')}
            >creator</h1>

            <h1 
            id="explorer"
            className={role === 'explorer' ? 'selected' : '' }
            onClick={() => setRole('explorer')}
            >explorer</h1>

            <input
            placeholder="email" 
            id="email"
            type="email"
            onChange={handleInputChange}
            />

            <input
            placeholder="password" 
            id="password"
            type="password"
            onChange={handleInputChange}
            />

            <input
            id="checkbox"
            type="checkbox" 
            checked={isCheckboxChecked}
            onChange={handleCheckbox}
            />

            <p id="terms" onClick={() => navigate('/terms')}>terms and condition</p>

            <button 
            id="register-button"
            onClick={handleRegisterClick}
            
            >register</button>

            {localError && <p className="error"> {localError} </p> }

           </div>
           <h1 id="login-link" onClick={() => navigate('/login')}>login</h1>
        </div>
    )
}

export default Register;