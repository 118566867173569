import React from "react";
import './App.css'
import { UserProvider } from "./firebase/userContext";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Register from "./pages/onboarding/register";
import Category from "./pages/onboarding/category";
import EditProfile from "./pages/profile/editProfile";
import Creator from "./pages/profile/creator";

import UploadHandler from "./pages/profile/upload/uploadHandler";
import Home from "./pages/home/home";
import SocialHome from "./pages/home/socialHome";
import StoreHome from "./pages/home/storeHome";
import MusicHome from "./pages/home/musicHome";
import FilmHome from "./pages/home/filmHome";
import OtherUser from "./pages/profile/otherUsers/otherUser";
import Notification from "./pages/profile/notification/notification";
import Settings from "./pages/settings/settings";
import Login from "./pages/onboarding/login";
import Username from "./pages/onboarding/username";
import ExplorerProfile from "./pages/profile/explorer/explorerProfile";
import Password from "./pages/password";
import Terms from "./pages/onboarding/terms";

const App = () => {
  return (
    <Router>
      <UserProvider>
      <Routes>
         <Route path="/register" element={<Register />} />
         <Route path="/" element={<Password />} />
         <Route path="/category" element={<Category />} />
         <Route path="/edit-profile/:username" element={<EditProfile />} />
         <Route path="/creator/:username" element={<Creator />} />
         <Route path="/home/:username" element={<Home />} />
         <Route path="/social/:username" element={<SocialHome />} />
         <Route path="/upload/:category" element={<UploadHandler />} />
         <Route path="/store/:username" element={<StoreHome />} />
         <Route path="/music/:username" element={<MusicHome />} />
         <Route path="/films/:username" element={<FilmHome />} />
         <Route path="/creators/:username" element={<OtherUser />} />
         <Route path="/notification/:username" element={<Notification />} />
         <Route path="/settings" element={<Settings />} />
         <Route path="/login" element={<Login />} />
         <Route path="/username" element={<Username />} />
         <Route path="/explorer/:username" element={<ExplorerProfile />} />
         <Route path="/terms" element={<Terms />} />
         
      </Routes>
      </UserProvider>
    </Router>
  )
}

export default App;