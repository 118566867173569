import React from 'react';

const SocialOptions = ({ onChange }) => {
    return (
        <div>
            <select id='social-category' onChange={onChange}>
                <option value="">Please select</option>
                <option value="Blog">Blog</option>
                <option value="Stylist">Stylist</option>
                <option value="Model">Model</option>
                <option value="Photographer">Photographer</option>
                <option value="Designer">Designer</option>
                </select> 
        </div>
    )
}

export default SocialOptions;