import React, { useState, useEffect } from "react";
// import { UserContext } from "../../../firebase/userContext";
import { db } from "../../../firebase/config";
import { collection, query, getDocs } from "firebase/firestore";

const OtherUserFilmGallery = ({ uid, role }) => {
    const [films, setFilms] = useState([]);
    const [error, setError] = useState("");
    const [selectedFilm, setSelectedFilm] = useState(null);
    const [isVideoPlaying, setIsVideoPlaying] = useState(false);

    useEffect(() => {
        const fetchFilms = async () => {
            try {
                const filmsRef = collection(db, `${role}/${uid}/films`); // Adjust the path based on the user role
                const q = query(filmsRef);
                const querySnapshot = await getDocs(q);
                const filmList = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data(),
                }));
                setFilms(filmList);
            } catch (err) {
                console.error("Error fetching films:", err);
                setError("Failed to fetch films. Please try again.");
            }
        };

        fetchFilms();
    }, [uid, role]);

    const handleFilmClick = (film) => {
        setSelectedFilm(film);
        setIsVideoPlaying(false); // Reset video playing state
    };

    const handleCloseModal = () => {
        setSelectedFilm(null);
        setIsVideoPlaying(false); // Stop video playback when closing modal
    };

    const handlePosterClick = () => {
        setIsVideoPlaying(true); // Start playing video
    };

    return (
        <div id="filmgallery-layout">
            {error && <p id="error-message">{error}</p>}
           
                {films.map((film) => (
                    <div key={film.id} className="filmgallery-container" onClick={() => handleFilmClick(film)}>
                        <img
                            src={film.posterUrl}
                            className="filmposter-gallery"
                            alt={`Poster of ${film.name}`}
                        />
                        <p>{film.name}</p>
                    </div>
                ))}
            

            {selectedFilm && (
                <div className="modal-overlay" onClick={handleCloseModal}>
                    <div className="modal-content-film" onClick={(e) => e.stopPropagation()}>
                    <p className="close-modal" onClick={handleCloseModal}>x</p>
                        {isVideoPlaying ? (
                            <video
                                className="modal-video-player"
                                controls
                                autoPlay
                                src={selectedFilm.filmUrl}
                                onClick={(e) => e.stopPropagation()} // Prevent closing modal when clicking video
                            />
                        ) : (
                            <div id="film-modal-poster">
                                <img
                                    src={selectedFilm.posterUrl}
                                    className="modal-poster-film"
                                    alt={`Poster of ${selectedFilm.name}`}
                                    onClick={handlePosterClick}
                                />
                                <p id="video-playback-symbol" onClick={handlePosterClick}>▶</p>
                            </div>
                        )}
                        <h1 className="modal-filmtitle">{selectedFilm.name}</h1>
                        <p className="modal-film-description"> <span style={{color: 'brown'}} >Description:</span> {selectedFilm.description}</p>
                        <p className="modal-film-genre"> <span style={{color: 'brown'}}>Genre:</span> {selectedFilm.genre}</p>
                    </div>
                </div>
            )}
        </div>
    );
};

export default OtherUserFilmGallery;