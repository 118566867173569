/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from '../assets/logos/logo1.png'

const Password = () => {
    const predefinedPassword = 'admin-user'; // Predefined password
    const [inputPassword, setInputPassword] = useState(''); // To store user input
    const navigate = useNavigate();

    const handleSubmit = () => {
        // Check if the entered password matches the predefined password
        if (inputPassword === predefinedPassword) {
            
            navigate('/register'); // Navigate if the password is correct
        } else {
            alert('Incorrect password');
        }
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            height: '100vh',
            gap: '15px'
            }}>
         <img src={logo} id="logo-password" style={{
            position: 'absolute',
            width: '70%',
            maxWidth: '250px',
            top: '30%'
         }} />
            <input
                type="password"
                placeholder="Enter password"
                value={inputPassword} // Controlled input
                onChange={(e) => setInputPassword(e.target.value)} // Update input password
            />
            <button onClick={handleSubmit}>Submit</button>
        </div>
    );
};

export default Password;
