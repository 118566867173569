/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useContext } from "react";
import { UserContext} from '../../firebase/userContext'
import './category.css'
import logo from '../../assets/logos/logo1.png'

const Category = () => {
    const [selectedCategory, setSelectedCategory] = useState('');
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const { handleCategoryAndUsername } = useContext(UserContext)

    const handleCategorySelection = (category) => {
        setSelectedCategory(category);
        setError('')
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
        setError(''); // Clear error when username is changed
    };

    const HandleSaveCategory = async () => {
        setError('');
        const errorMessage = await handleCategoryAndUsername(selectedCategory, username);
        if (errorMessage) {
            setError(errorMessage);
        }
    };

    return (
        <div id="category-layout">
                <img src={logo} id="logo-category" />
            <div id="category-container">
                <p id="choose-category">choose a category</p>
                
                <h1 
                id="social"
                className={`categories ${selectedCategory === 'social' ? 'selected' : ''}`}
                onClick={() => handleCategorySelection('social')}
                >social</h1>
                
                <h1 
                id="music"
                className={`categories ${selectedCategory === 'music' ? 'selected' : ''}`}
                onClick={() => handleCategorySelection('music')}
               >music</h1>

                <h1 
                id="film"
                className={`categories ${selectedCategory === 'film' ? 'selected' : ''}`}
                onClick={() => handleCategorySelection('film')}
                >film</h1>
                
                <p id="create-username">create an username:</p>
                <input
                id="username"
                placeholder="username" 
                value={username}
                onChange={handleUsernameChange}
                />

                <button onClick={HandleSaveCategory} id="category-button">save</button>
                {error && <p className="error"> {error} </p>}
            </div>
        </div>
    )
}

export default Category;